<template>
  <div class="fee-setting">
    <b-card
      title="Payout Fee Settings"
    >
      <hr>
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="A"
        class="mb-1"
      >
        Processing Fee
      </b-form-radio>
      <b-row
        v-for="(item, index) in processingFee"
        :key="index"
      >
        <b-col md="1">
          <b-form-group>
            <b-form-input
              class="text-center"
              :value="`Bracket ${index+1}:`"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group>
            <b-form-input />
          </b-form-group>
        </b-col>
        <b-col
          md="1"
        >
          <b-form-group>
            <b-form-input
              class="text-center"
              value="——"
              plaintext
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group>
            <b-form-input />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group>
            <b-form-input type="number" />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <v-select
            :options="currencyOptions"
            :clearable="false"
          />
        </b-col>
        <b-col md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="btn-icon mr-2"
            @click="add(index)"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon"
            @click="del"
          >
            <feather-icon icon="MinusIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import {
  BFormRadio, BCard,
  BRow, BCol, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default defineComponent({
  components: {
    BFormRadio,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
  },
  setup() {
    const selected = ref('A')
    const currencyOptions = ref([
      { label: '%', value: '%' },
      { label: 'BRL', value: 'BRL' },
      { label: 'MXN', value: 'MXN' },
    ])
    const processingFee = ref([{}])
    const add = index => {
      // processingFee.value.push({})
      processingFee.value.splice(index + 1, 0, {})
    }
    const del = () => {
      if (processingFee.value.length === 1) return
      processingFee.value.splice(0, 1)
    }
    return {
      selected,
      currencyOptions,
      processingFee,
      add,
      del,
    }
  },
  directives: {
    Ripple,
  },
})
</script>

<style lang="scss">
// .fee-setting {

// }
</style>
