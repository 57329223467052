<template>
  <div class="merchant-basic-detail">
    <b-card
      title="Basic Info"
    >
      <hr>
      <b-row class="mt-2">
        <b-col
          v-for="(item, index) in infos"
          :key="index"
          md="4"
          class="mb-2"
        >
          <b-row>
            <b-col
              md="6"
              class="info-key"
            >
              {{ item.key }}
            </b-col>
            <b-col
              v-if="item.key === 'Merchant Status'"
              md="6"
              class="info-value"
            >
              {{ isChecked?'ACTIVE':'INACTIVE' }}
              <b-form-checkbox
                v-model="isChecked"
                switch
                @change="changeStatus"
              />
            </b-col>
            <b-col
              v-else
              md="6"
              class="info-value"
            >
              {{ item.value }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="KYC Information"
    >
      <hr>
      <b-row class="mt-2">
        <b-col
          v-for="(item, index) in informations"
          :key="index"
          md="4"
          class="mb-2"
        >
          <b-row>
            <b-col
              md="6"
              class="info-key"
            >
              {{ item.key }}
            </b-col>
            <b-col
              md="6"
              class="info-value"
            >
              {{ item.value }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Business Documents"
    >
      <hr>
      <b-row class="mt-2">
        <b-col
          md="6"
          class="text-center pl-5 pr-5"
        >
          <span class="info-key">Company Charter</span>
          <b-img
            src="https://picsum.photos/1024/400/?image=41"
            fluid
            alt="Company Charter"
            class="mt-1 cursor-pointer"
            @click="$previewPictrue('https://picsum.photos/1024/400/?image=41')"
          />
        </b-col>
        <b-col
          md="6"
          class="text-center pl-5 pr-5"
        >
          <span class="info-key">Registration Certificate</span>
          <b-img
            src="https://picsum.photos/1024/400/?image=41"
            fluid
            alt="Registration Certificate"
            class="mt-1 cursor-pointer"
            @click="$previewPictrue('https://picsum.photos/1024/400/?image=41')"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Director Documents"
    >
      <hr>
      <b-row class="mt-2">
        <b-col
          md="6"
          class="text-center pl-5 pr-5"
        >
          <span class="info-key">Director 1 - ID Document</span>
          <b-img
            src="https://picsum.photos/1024/400/?image=41"
            fluid
            alt="Director 1 - ID Document"
            class="mt-1 cursor-pointer"
            @click="$previewPictrue('https://picsum.photos/1024/400/?image=41')"
          />
          <b-img
            src="https://picsum.photos/1024/400/?image=41"
            fluid
            alt="Director 1 - ID Document"
            class="mt-2 cursor-pointer"
            @click="$previewPictrue('https://picsum.photos/1024/400/?image=41')"
          />
        </b-col>
        <b-col
          md="6"
          class="text-center pl-5 pr-5"
        >
          <span class="info-key">Director 2 - Passport</span>
          <b-img
            src="https://picsum.photos/1024/400/?image=41"
            fluid
            alt="Director 2 - Passport"
            class="mt-1 cursor-pointer"
            @click="$previewPictrue('https://picsum.photos/1024/400/?image=41')"
          />
          <div class="mt-2 text-left info-key">
            Place of Birth: XXXXX
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="Beneficiary Documents"
    >
      <hr>
      <b-row class="mt-2">
        <b-col
          md="6"
          class="text-center pl-5 pr-5"
        >
          <span class="info-key">Beneficiary 1 - ID Document</span>
          <b-img
            src="https://picsum.photos/1024/400/?image=41"
            fluid
            alt="Beneficiary 1 - ID Document"
            class="mt-1 cursor-pointer"
            @click="$previewPictrue('https://picsum.photos/1024/400/?image=41')"
          />
          <b-img
            src="https://picsum.photos/1024/400/?image=41"
            fluid
            alt="Beneficiary 1 - ID Document"
            class="mt-2 cursor-pointer"
            @click="$previewPictrue('https://picsum.photos/1024/400/?image=41')"
          />
        </b-col>
        <b-col
          md="6"
          class="text-center pl-5 pr-5"
        >
          <span class="info-key">Beneficiary 2 - Passport</span>
          <b-img
            src="https://picsum.photos/1024/400/?image=41"
            fluid
            alt="Beneficiary 2 - Passport"
            class="mt-1 cursor-pointer"
            @click="$previewPictrue('https://picsum.photos/1024/400/?image=41')"
          />
          <div class="mt-2 text-left info-key">
            Place of Birth: XXXXX
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      title="KYC Check"
    >
      <hr>
      <b-row class="mt-2">
        <b-col md="4">
          <v-select
            :options="kycCheckOptions"
            :clearable="false"
          />
        </b-col>
        <b-col md="4">
          <b-button
            variant="primary"
            @click="save"
          >Save</b-button>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import {
  defineComponent, getCurrentInstance, ref, computed,
} from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  props: ['merchantDetail'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const basicInfo = ref({})
    const kycCheckOptions = ref([
      { label: 'Waiting Approval', value: 'Waiting Approval' },
      { label: 'Approved', value: 'Approved' },
      { label: 'Rejected', value: 'Rejected' },
    ])
    const isChecked = ref(false)
    // const infos = ref([
    //   { key: 'Company Name', value: 'Test 123' },
    //   { key: 'Creation Date', value: '2023-04-05' },
    //   { key: 'Merchant Status', value: '' },
    //   { key: 'Merchant ID', value: 'SGDHAH3283' },
    //   { key: 'Merchant Key', value: 'SGSGSAG36273' },
    // ])
    // const informations = ref([
    //   { key: 'Company Full Name', value: 'Test 123' },
    //   { key: 'Creation Date', value: '2023-04-05' },
    //   { key: 'Registered Country', value: 'HONG KONG' },
    //   { key: 'Contact Name', value: 'Tom Smith' },
    //   { key: 'Email', value: 'smith1@gmail.com' },
    //   { key: 'Company Registration Number', value: 'SGDHGA37474' },
    //   { key: 'Phone Number', value: '13120190544' },
    //   { key: 'Website', value: 'test.com' },
    //   { key: 'Industry', value: 'FINANCE' },
    //   { key: 'Funds recipient country', value: 'BRAZIL' },
    //   { key: 'Expected transaction amount', value: '0 - 100k' },
    //   { key: 'Funds origin country', value: 'BRAZIL' },
    //   { key: 'Purpose of use', value: 'E-COMMERCE' },
    // ])

    const changeStatus = () => {
      if (isChecked.value) {
        proxy.$swal({
          title: 'Change Merchant Status',
          html: "<p class='text-left'>Changing status to active will activate this merchant. They will be able to perform transactions and move their balance regularly. Please confirm you have permission to activate.</p> <p class='text-left'>Do you wish to activate this merchant?</p>",
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: 'Continue',
        }).then(result => {
          if (!result.value) {
            isChecked.value = !isChecked.value
          }
        })
      } else {
        proxy.$swal({
          title: 'Change Merchant Status',
          html: "<p class='text-left'>Changing status to inactive will deactivate this merchant. They will no longer be able to make transactions and their assets will be frozen.</p> <p class='text-left'>Are you sure you wish to continue?</p>",
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: 'Continue',
        }).then(result => {
          if (!result.value) {
            isChecked.value = !isChecked.value
          }
        })
      }
      // proxy.$showLoading()
      // const res = await proxy.$api.changeMerchantStatus({ merchant_id: proxy.$route.query.id,"merchant_status":0 }).catch(() => { proxy.$hideLoading() })
      // proxy.$hideLoading()
      // if (!res) { return }
      // const { code, data, message } = res.data
      // if (code === 200) {
      //   console.log(data)
      // } else {
      //   proxy.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Notification',
      //       icon: 'BellIcon',
      //       text: `${message}`,
      //       variant: 'danger',
      //     },
      //   }, {
      //     position: 'top-center',
      //   })
      // }
    }
    const save = () => {
      proxy.$swal({
        title: 'KYC Check Confirmation',
        html: "<p class='text-left' style='word-break: break-all;'>By clicking confirm, you will change the KYC status of this merchant. This may impact the merchant's functionality and abillity to conduct transactions. </p> <p class='text-left' style='word-break: break-all;'>Please ensure you have appropriate permission before continuing.</p>",
        icon: 'info',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then(result => {
        if (result.value) {
          // proxy.$showLoading()
          // const res = await proxy.$api.changeMerchantStatus({ merchant_id: proxy.$route.query.id,"merchant_status":0 }).catch(() => { proxy.$hideLoading() })
          // proxy.$hideLoading()
          // if (!res) { return }
          // const { code, data, message } = res.data
          // if (code === 200) {
          //   console.log(data)
          // } else {
          //   proxy.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: 'Notification',
          //       icon: 'BellIcon',
          //       text: `${message}`,
          //       variant: 'danger',
          //     },
          //   }, {
          //     position: 'top-center',
          //   })
          // }
        }
      })
    }

    // 赋值
    const infos = computed(() => {
      if (!props.merchantDetail.base_info) {
        return []
      }
      return [
        { key: 'Company Name', value: props.merchantDetail.base_info.company_full_name },
        { key: 'Creation Date', value: '' },
        { key: 'Merchant Status', value: '' },
        { key: 'Merchant ID', value: props.merchantDetail.base_info.merchant_id },
        { key: 'Merchant Key', value: props.merchantDetail.base_info.merchant_sn },
      ]
    })
    const informations = computed(() => {
      if (!props.merchantDetail.kyc_info) {
        return []
      }
      return [
        { key: 'Company Full Name', value: props.merchantDetail.base_info.company_full_name },
        { key: 'Creation Date', value: '' },
        { key: 'Registered Country', value: '' },
        { key: 'Contact Name', value: props.merchantDetail.base_info.contact_name },
        { key: 'Email', value: props.merchantDetail.base_info.company_email },
        { key: 'Company Registration Number', value: props.merchantDetail.kyc_info.company_number },
        { key: 'Phone Number', value: props.merchantDetail.base_info.phone },
        { key: 'Website', value: props.merchantDetail.kyc_info.website },
        { key: 'Industry', value: props.merchantDetail.kyc_info.industry_code },
        { key: 'Funds recipient country', value: '' },
        { key: 'Expected transaction amount', value: '' },
        { key: 'Funds origin country', value: '' },
        { key: 'Purpose of use', value: props.merchantDetail.details.purpose },
      ]
    })

    return {
      basicInfo,
      kycCheckOptions,
      isChecked,
      infos,
      informations,
      changeStatus,
      save,
    }
  },
})
</script>

<style lang="scss">
.merchant-basic-detail {
  .info-key {
    color: #333333;
  }
  .info-value {
    color: #AAAAAA;
  }
}
</style>
