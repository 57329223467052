<template>
  <div class="bank-account">
    <b-card
      title="Bank Information"
    >
      <hr>
      <table-list
        :table-data.sync="tableData"
        :list.sync="tableList"
        :page-num.sync="currentPage"
        :total-list.sync="totalList"
      />
    </b-card>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { BCard } from 'bootstrap-vue'

export default defineComponent({
  components: { BCard },
  props: ['merchantDetail'],
  setup() {
    const currentPage = ref(1)
    const tableList = ref([
      {
        account_name: 'Test Smith', email: 'test@gmail.com', address: 'test address', bank_name: 'Bank of Brazil', account_number: '1452 25254', country: 'Brazil', branch: 'Paulista Branch', currency: 'BRL',
      },
    ])
    const totalList = ref(0)
    const tableData = ref({
      tableColumns: [
        { key: 'account_name', label: 'Account Name' },
        { key: 'email', label: 'Email' },
        { key: 'address', label: 'Address' },
        { key: 'bank_name', label: 'Bank Name' },
        { key: 'account_number', label: 'Account Number' },
        { key: 'country', label: 'Country' },
        { key: 'branch', label: 'Branch' },
        { key: 'currency', label: 'Currency' },
      ],
      templates: [
        { key: 'registration_date', tType: 'datetime' },
        { key: 'merchant_id', tType: 'merchant_merchant_id' },
      ],
    })

    return {
      tableData,
      tableList,
      currentPage,
      totalList,
    }
  },
})
</script>

<style lang="scss">
.bank-account {

}
</style>
