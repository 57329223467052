<template>
  <div class="detail-wrap">
    <div class="detail-title">
      Merchant ID: <span style="color:#7B70F0;">{{ $route.query.id }}</span> - Basic Details
    </div>
    <b-tabs
      v-model="tabIndex"
      pills
      class="mt-2 mb-2"
    >
      <b-tab
        title="BASIC DETAILS"
      >
        <basic-detail :merchant-detail.sync="detail" />
      </b-tab>
      <b-tab
        title="BANK ACCOUNTS"
      >
        <bank-accounts :merchant-detail.sync="detail" />
      </b-tab>
      <b-tab
        title="FEE SETTINGS"
      >
        <fee-settings />
      </b-tab>
      <b-tab title="CHANNEL SETTINGS">
        <channel-settings />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  defineComponent, getCurrentInstance, ref, onMounted,
} from '@vue/composition-api'
import { BTabs, BTab } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BasicDetail from '@/views/merchantCenter/merchantList/components/basicDetails.vue'
import BankAccounts from '@/views/merchantCenter/merchantList/components/bankAccounts.vue'
import FeeSettings from '@/views/merchantCenter/merchantList/components/feeSettings.vue'
import ChannelSettings from '@/views/merchantCenter/merchantList/components/channelSettings.vue'

export default defineComponent({
  components: {
    BTabs,
    BTab,

    BasicDetail,
    BankAccounts,
    FeeSettings,
    ChannelSettings,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    console.log(proxy.$route.query.id)
    const tabIndex = ref(0)

    const detail = ref({})
    onMounted(async () => {
      setTimeout(() => {
        detail.value = {
          base_info: {
            merchant_id: '测试-id', // 商户ID string
            merchant_sn: '测试-sn', // 商户SN string
            merchant_name: '', // 商户名称 string
            company_full_name: '测试-公司全称', // 公司全名 string
            company_email: '测试-公司邮箱', // 公司邮箱 string
            country_code: '', // string
            phone: '测试-phone', // string
            area_code: '', // string
            currency: '', // string
            contact_name: '测试-联系人', // string
          },
          kyc_info: {
            country_code: '', // string
            company_number: '测试-company_number', // string
            industry_code: '测试-industry_code', // string
            currency: '', // string
            website: '测试-website', // string
          },
          documents: {
            company_certificate_url: '', // string
            company_code_url: '', // string
            director: [
              {
                country_code: '', // string
                id_front_url: '', // string
                id_back_url: '', // string
                passport_url: '', // string
                birth_place: '', // string
              },
            ],
            beneficiary: [
              {
                country_code: '', // string
                id_front_url: '', // string
                id_back_url: '', // string
                passport_url: '', // string
                birth_place: '', // string
              },
            ],
          },
          details: {
            country_code_send: '', // string
            country_code_receive: '', // string
            total_amount_receive: '', // string
            purpose: 'test-purpose', // string
          },
          bank_list: [
            {
              merchant_bank_id: '', // string
              account_name: '', // string
              email: '', // string
              address: '', // string
              bank_name: '', // string
              account_number: '', // string
              country: '', // string
              bank_branch: '', // string
              currency: '', // string
            },
          ],
        }
      }, 1000)
      // proxy.$showLoading()
      // // 商户状态：3处理中 4通过 5拒绝 number
      // const res = await proxy.$api.changeMerchantKycStatus({ merchant_id: proxy.$route.query.id, kyc_status: 0 }).catch(() => { proxy.$hideLoading() })
      // proxy.$hideLoading()
      // if (!res) { return }
      // const { code, data, message } = res.data
      // if (code === 200) {
      //   console.log(data)
      // } else {
      //   proxy.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'Notification',
      //       icon: 'BellIcon',
      //       text: `${message}`,
      //       variant: 'danger',
      //     },
      //   }, {
      //     position: 'top-center',
      //   })
      // }
    })

    return {
      tabIndex,
      detail,
    }
  },
})
</script>

<style lang="scss">
.detail-wrap {
  width: 95%;
  padding: 4rem 0;
  margin: 0 auto;
  min-height: 400px;
  .detail-title {
    font-size: 24px;
  }
}
</style>
