<template>
  <div>
    <b-card
      title="Accepted Channels"
    >
      <hr>
      <p> <span style="color:#7B70F0;">#Merchant Name</span>  accepts the following payment channels</p>
      <b-form-radio
        v-model="selected"
        name="some-radios"
        value="A"
        class="mb-1"
      >
        Pix
      </b-form-radio>
      <b-row>
        <b-col
          md="6"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
          >Save</b-button>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import {
  BFormRadio, BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default defineComponent({
  components: {
    BFormRadio, BCard, BButton, BRow, BCol,
  },
  directives: {
    Ripple,
  },
  setup() {
    const selected = ref('A')
    return {
      selected,
    }
  },
})
</script>
